import styled from 'styled-components'
import { getInputStyles } from '../Input/Input.styles'
import { spacing } from '../_styles/spacing'
import type { TextAreaProps } from './TextArea.types'

export const StyledTextArea = styled.textarea<TextAreaProps>`
  ${getInputStyles}

  min-height: 64px;
  padding: ${spacing.sm}px ${spacing.md}px;
  resize: ${({ resize = 'both' }) => resize};
`
