import { Cog } from '@procore/core-icons'
import React from 'react'
import { Tabs as TabsBase } from '../Tabs/Tabs'
import { useI18nContext } from '../_hooks/I18n'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type { DivAttributes, Props, SVGAttributes } from '../_utils/types'
import { ActionIcon } from './ActionIcon'
import {
  StyledHeaderActions,
  StyledHeaderBadge,
  StyledHeaderTabs,
  StyledToolHeader,
  StyledToolHeaderIconContainer,
  StyledToolHeaderTitle,
} from './ToolHeader.styles'
import type {
  ToolHeaderBadgeProps,
  ToolHeaderIconProps,
  ToolHeaderSettingsActionPolyType,
  ToolHeaderSettingsActionProps,
  ToolHeaderSettingsActionRef,
} from './ToolHeader.types'

const ToolHeader_ = React.forwardRef<HTMLDivElement, any>((props, ref) => {
  return <StyledToolHeader ref={ref} {...props} />
})

export const Icon = React.forwardRef<
  HTMLDivElement,
  SVGAttributes & ToolHeaderIconProps
>(function Icon({ className, icon: icon_, ...props }, ref) {
  const icon = icon_ ? (
    React.cloneElement(icon_ as React.ReactElement, { ...props, size: 'sm' })
  ) : (
    <Cog {...props} size="sm" />
  )
  return (
    <StyledToolHeaderIconContainer className={className} ref={ref}>
      {icon}
    </StyledToolHeaderIconContainer>
  )
})

function SettingsActionInner<Role extends ToolHeaderSettingsActionPolyType>(
  props: ToolHeaderSettingsActionProps<Role>,
  ref: ToolHeaderSettingsActionRef<Role>
) {
  const i18n = useI18nContext()

  const Comp = ActionIcon as any

  return (
    <Comp
      aria-label={i18n.t('core.toolHeader.settingsAction')}
      ref={ref}
      {...props}
    >
      <ToolHeader.Icon />
    </Comp>
  )
}

const SettingsAction = React.forwardRef(SettingsActionInner) as <
  Role extends ToolHeaderSettingsActionPolyType
>(
  props: ToolHeaderSettingsActionProps<Role>
) => ReturnType<typeof SettingsActionInner>

export const Title = StyledToolHeaderTitle

export const Tabs = React.forwardRef<HTMLDivElement, DivAttributes & Props>(
  function Tabs({ children, className, ...props }, ref) {
    return (
      <StyledHeaderTabs className={className} ref={ref}>
        <TabsBase {...props}>{children}</TabsBase>
      </StyledHeaderTabs>
    )
  }
)

export const Actions = StyledHeaderActions

/**
 * @deprecated
 *
 * This `Badge` pattern is no longer supported and it is now recommended to use a `Pill` instead.
 *
 * @deprecatedSince 10.20.0
 *
 * @see https://core.procore.com/latest/web/components/pill/react/
 */
export const Badge = React.forwardRef<
  HTMLDivElement,
  DivAttributes & ToolHeaderBadgeProps
>(function Badge({ children, variant, ...props }, ref) {
  return (
    <StyledHeaderBadge {...props} $variant={variant} ref={ref}>
      {children}
    </StyledHeaderBadge>
  )
})

ToolHeader_.displayName = 'ToolHeader'

Icon.displayName = 'ToolHeader.Icon'

Title.displayName = 'ToolHeader.Title'

Tabs.displayName = 'ToolHeader.Tabs'

Actions.displayName = 'ToolHeader.Actions'

Badge.displayName = 'ToolHeader.Badge'

export const ToolHeader = addSubcomponents(
  {
    Icon,
    Title,
    Tabs,
    Actions,
    Badge,
    SettingsAction,
  },
  ToolHeader_
)
