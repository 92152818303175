import styled, { css } from 'styled-components'
import { Card } from '../Card/Card'
import { StyledSection, StyledSectionInner } from '../Section/Section.styles'
import { Typography } from '../Typography/Typography'
import { colors } from '../_styles/colors'
import { getShadow } from '../_styles/shadows'
import { spacing } from '../_styles/spacing'

const headerActionSize = 36
const buttonShadowWidth = 2

const gutter = css`
  > * + * {
    margin-left: ${spacing.sm}px;
  }
`

const panel = css`
  display: flex;
  align-items: center;
  padding: ${spacing.lg}px;
  background: ${colors.white};
`

export const StyledHeader = styled.div`
  border-bottom: 1px solid ${colors.gray85};

  ${panel}
`

export const StyledTitle = styled(Typography)`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const StyledHeaderBackAction = styled.div<{ active: boolean }>`
  transition: all 300ms ease-out;
  flex-shrink: 0;

  width: ${({ active }) => (active ? headerActionSize : 0)}px;
  margin-right: ${({ active }) => (active ? spacing.lg : 0)}px;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transform: translateX(
    ${({ active }) =>
      active ? 0 : -(spacing.lg + headerActionSize + buttonShadowWidth)}px
  );
`

export const StyledMoreMenu = styled(Card)`
  max-height: 128px;
`

export const StyledHeaderActions = styled.div`
  display: flex;
  margin-left: ${spacing.lg}px;
  flex-shrink: 0;

  ${gutter}
`

export const StyledCloseButton = styled.div`
  margin-left: ${spacing.sm}px;
`

export const StyledBody = styled.div<{ secondaryBgColor?: boolean }>`
  height: 100%;
  background: ${({ secondaryBgColor }) =>
    secondaryBgColor ? colors.gray96 : colors.white};
  overflow-y: auto;

  > ${StyledSection} {
    padding-left: ${spacing.lg}px;
    padding-right: ${spacing.lg}px;

    ${StyledSection} {
      padding-left: 0;
      padding-right: 0;
    }

    &:first-child > ${StyledSectionInner} {
      padding-top: ${spacing.lg}px;
    }
  }
`

export const StyledPanel = styled.aside`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${colors.white};
  overflow: hidden;
`

export const StyledFooterNotation = styled.div`
  flex-grow: 1;
`

export const StyledFooterActions = styled.div`
  ${gutter}
`

export const StyledFooter = styled.div<{ $hasShadow: boolean }>`
  border-top: 1px solid ${colors.gray85};
  justify-content: flex-end;

  ${panel}

  ${({ $hasShadow }) =>
    $hasShadow &&
    css`
      ${getShadow(2, 'top')}
      z-index: 1;
    `}
`
