import { Clear } from '@procore/core-icons/dist'
import { getDatePartsWithPlaceholders } from '@procore/globalization-toolkit'
import { isSameDay } from 'date-fns'
import React from 'react'
import { Button } from '../Button/Button'
import { useDateTime } from '../_hooks/DateTime'
import { useI18nContext } from '../_hooks/I18n'
import {
  getMaxYear,
  maxMonth,
  minYear,
  normalizeNewDate,
} from '../_utils/CalendarHelpers'
import type { DivAttributes } from '../_utils/types'
import {
  StyledCalendar,
  StyledDateInput,
  StyledDateInputDelimiter,
  StyledDateInputIconContainer,
  StyledDateInputSegment,
  StyledDateSegmentsContainer,
} from './DateInput.styles'
import type {
  DateInputApi,
  DateInputConfig,
  DateInputLocales,
  DateInputProps,
  DateSegmentProps,
  Segments,
  SegmentType,
} from './DateInput.types'

const segmentMaxLengths: Segments = {
  day: 2,
  month: 2,
  year: 4,
}

const dateInputLocales: DateInputLocales = {
  'fr-CA': {
    placeholders: { day: 'jj', month: 'mm', year: 'aaaa' },
  },
  'fr-FR': {
    placeholders: { day: 'jj', month: 'mm', year: 'aaaa' },
  },
  es: {
    placeholders: { day: 'dd', month: 'mm', year: 'aaaa' },
  },
  'es-ES': {
    placeholders: { day: 'dd', month: 'mm', year: 'aaaa' },
  },
  'pt-BR': {
    placeholders: { day: 'dd', month: 'mm', year: 'aaaa' },
  },
  'is-IS': {
    placeholders: { day: 'dd', month: 'mm', year: 'áááá' },
  },
  'de-DE': {
    placeholders: { day: 'tt', month: 'MM', year: 'jjjj' },
  },
}

const psuedoSegmentOrder: SegmentType[] = ['day', 'month', 'year']

export function isValidYearRange(year: number) {
  return year > 1700 && year < 2122
}

function noop() {}

function getLastDate(month: number, year: number) {
  return normalizeNewDate(year, Math.max(0, month), 0).getDate()
}

function getSegmentProps(
  onChangeSegment: (type: SegmentType, value: number) => void,
  type: SegmentType,
  placeholder: string,
  dateInput: DateInputApi,
  getAriaLabel: (type: SegmentType, value: number) => string
) {
  const maxLength = segmentMaxLengths[type]

  if (type === 'day') {
    return {
      'aria-label': getAriaLabel('day', dateInput.day),
      maxLength,
      maxValue: getLastDate(dateInput.month, dateInput.year),
      minValue: 1,
      onChange: (value: number) => {
        dateInput.setDay(value)
        onChangeSegment(type, value)
      },
      placeholder,
      value: dateInput.day,
    }
  } else if (type === 'month') {
    return {
      'aria-label': getAriaLabel('month', dateInput.month),
      maxLength,
      maxValue: maxMonth,
      minValue: 1,
      onChange: (value: number) => {
        dateInput.setMonth(value)
        onChangeSegment(type, value)
      },
      placeholder,
      value: dateInput.month,
    }
  } else {
    return {
      'aria-label': getAriaLabel('year', dateInput.year),
      maxLength,
      maxValue: getMaxYear(),
      minValue: minYear,
      onChange: (value: number) => {
        dateInput.setYear(value)
        onChangeSegment(type, value)
      },
      placeholder,
      value: dateInput.year,
    }
  }
}

function setFocusTo(target?: React.RefObject<HTMLDivElement>) {
  return target && target.current && target.current.focus()
}

function focusTargetOrFirst(...refs: React.RefObject<HTMLDivElement>[]) {
  return function handler(event: any = { target: null }) {
    const ref = refs.reduce((acc, ref) => {
      return ref.current && ref.current === event.target ? ref : acc
    }, refs[0])

    setFocusTo(ref)
  }
}

function clampDay(day: number, month: number, year: number) {
  if (day > 0 && month > 0 && year) {
    const date = normalizeNewDate(year, month - 1, day)

    if (date.getMonth() !== month - 1) {
      return getLastDate(month, year)
    }
  }

  return day
}

function useDateInput({
  onChange,
  value: value_,
  log,
}: DateInputConfig): DateInputApi {
  const dateTime = useDateTime()

  // logging called too frequenlty if not in memo
  const value = React.useMemo(() => {
    return dateTime.shiftUtcToZonedTime(value_, log)
  }, [value_])

  const [day, setRawDay] = React.useState(value ? value.getDate() : -1)

  const [month, setRawMonth] = React.useState(value ? value.getMonth() + 1 : -1)

  const [year, setRawYear] = React.useState(value ? value.getFullYear() : -1)

  const hasValues = day >= 0 || month >= 0 || year >= 0

  const isInvalid = day < 0 || month < 0 || year < 0

  const setAll = (day: number, month: number, year: number) => {
    const clampedDay = clampDay(day, month, year)

    const date =
      clampedDay > 0 && month > 0 && year > 0
        ? normalizeNewDate(year, month - 1, clampedDay)
        : null

    setRawDay(clampedDay)
    setRawMonth(month)
    setRawYear(year)

    // Call DateSelect.onChange API when a supported year range or deleted date
    // isValidYearRange protects shiftZonedTimeToUtc
    // when date is null, year should be -1. -1 means it was cleared, either by button or backspace key
    /**
     * - going from non-valid date to a valid date
     *    - is a valid year range
     * - going from one valid date to another, different, date
     *    - is a valid year range
     * - change to invalid date
     */
    const validDate = date && isValidYearRange(year)
    if (
      (!value && validDate) ||
      (value && validDate && !isSameDay(date, value)) ||
      (value && date === null)
    ) {
      onChange(dateTime.shiftZonedTimeToUtc(date))
    }
  }

  const setDay = (value: number) => {
    setAll(value, month, year)
  }

  const setMonth = (value: number) => {
    setAll(day, value, year)
  }

  const setYear = (value: number) => {
    setAll(day, month, value)
  }

  const clear = () => {
    setAll(-1, -1, -1)
  }

  React.useEffect(() => {
    if (value) {
      setRawDay(value.getDate())
      setRawMonth(value.getMonth() + 1)
      setRawYear(value.getFullYear())
    } else if (value === undefined) {
      setRawDay(-1)
      setRawMonth(-1)
      setRawYear(-1)
    }
  }, [value])

  return {
    clear,
    day,
    hasValues,
    month,
    setDay,
    setMonth,
    setYear,
    year,
  }
}

function getSegmentText({
  maxLength,
  placeholder,
  type,
  value,
}: {
  maxLength: number
  placeholder: string
  type: SegmentType
  value: number
}) {
  if (value < 0) {
    return placeholder
  }
  if (type === 'year' && !isValidYearRange(value)) {
    return `${value}____`.slice(0, 4)
  }
  return String(value).padStart(maxLength, '0')
}

const getTodaySegmentValue = (type: DateSegmentProps['type']) => {
  const today = new Date()
  if (type === 'month') {
    // months start from zero
    return today.getMonth() + 1
  } else if (type === 'day') {
    return today.getDate()
  } else {
    return today.getFullYear()
  }
}

const DateSegment = React.forwardRef<
  HTMLDivElement,
  Omit<DivAttributes, 'onChange'> & DateSegmentProps
>(function DateSegment(
  {
    disabled = false,
    maxLength,
    maxValue,
    minValue,
    nextRef,
    onChange = (value: number) => {},
    placeholder,
    prevRef,
    tabIndex = 0,
    type,
    value = 0,
    ...props
  },
  ref
) {
  const I18n = useI18nContext()
  const dateTime = useDateTime()
  const [keyBuffer, setKeyBuffer] = React.useState('')

  const contains = (key: string, keys: string[]) => keys.indexOf(key) >= 0

  const onKeyDown = (event: React.KeyboardEvent<any>) => {
    event.stopPropagation()

    const key = event.key

    if (contains(key, ['Up', 'ArrowUp', 'Down', 'ArrowDown', 'Backspace'])) {
      event.preventDefault()
    }

    if (contains(key, ['Up', 'ArrowUp', 'Down', 'ArrowDown']) && value === -1) {
      onChange(getTodaySegmentValue(type))
    } else if (contains(key, ['Up', 'ArrowUp'])) {
      onChange(value + 1 > maxValue ? minValue : Math.max(1, value + 1))
    } else if (contains(key, ['Down', 'ArrowDown'])) {
      onChange(value - 1 < minValue ? maxValue : value - 1)
    } else if (contains(key, ['Left', 'ArrowLeft'])) {
      setFocusTo(prevRef)
    } else if (contains(key, ['Right', 'ArrowRight'])) {
      setFocusTo(nextRef)
    } else if (contains(key, ['Backspace', 'Delete'])) {
      setKeyBuffer('')
      onChange(-1)

      // the segment is currently empty, go to the previous segment
      if (value === -1) {
        setFocusTo(prevRef)
      }
    } else if (!isNaN(parseInt(key, 10))) {
      if (keyBuffer.length === 0) {
        // current buffer is empty, initialize it
        setKeyBuffer(key)
        onChange(parseInt(key, 10))
      } else {
        // current buffer has text, add to it
        const newBuffer = keyBuffer + key

        setKeyBuffer(newBuffer)
        onChange(Math.min(parseInt(newBuffer, 10), maxValue))
      }
    }
  }

  React.useEffect(() => {
    if (
      keyBuffer.length >= maxLength ||
      (keyBuffer.length === 1 &&
        ((type === 'day' && value > 3) || (type === 'month' && value > 1)))
    ) {
      setFocusTo(nextRef)

      // Needed for year to reset as it does not move focus
      setKeyBuffer('')
    }
  })

  const getMonthName = (month: number) => {
    const date = new Date()
    // months start from zero
    date.setMonth(month - 1)

    return dateTime.format(date, 'none', { month: 'long' })
  }

  const valueText =
    value === -1
      ? I18n.t('core.dateInput.segment.ariaValueText.empty')
      : type === 'month'
      ? `${value} - ${getMonthName(value)}`
      : `${value}`

  return (
    <StyledDateInputSegment
      ref={ref}
      {...props}
      role="spinbutton"
      aria-valuetext={valueText}
      aria-valuenow={value == -1 ? getTodaySegmentValue(type) : value}
      aria-valuemax={maxValue}
      aria-valuemin={minValue}
      data-placeholder={value < 0}
      disabled={disabled}
      isYear={type === 'year'}
      onBlur={() => {
        if (type === 'year' && value !== -1 && value < 100) {
          onChange(2000 + value)
        }

        setKeyBuffer('')
      }}
      onKeyDown={onKeyDown}
      tabIndex={disabled ? -1 : tabIndex}
    >
      {getSegmentText({ value, placeholder, type, maxLength })}
    </StyledDateInputSegment>
  )
})

/**
 * @deprecatedSince 11
 * @deprecated Intended for internal library development.
 */
export const DateInput = React.forwardRef<HTMLDivElement, DateInputProps>(
  function DateInput(
    {
      clearRef,
      disabled,
      error = false,
      segmentRefs = {},
      variant,
      onChange = noop,
      onChangeSegment = noop,
      onClear = noop,
      tabIndex = 0,
      value,
      ...props
    },
    ref
  ) {
    const I18n = useI18nContext()

    const dateInputRef = ref as React.RefObject<HTMLDivElement>

    const segment1Ref =
      segmentRefs.segmentOne || React.createRef<HTMLDivElement>()

    const segment2Ref =
      segmentRefs.segmentTwo || React.createRef<HTMLDivElement>()

    const segment3Ref =
      segmentRefs.segmentThree || React.createRef<HTMLDivElement>()

    const placeholders = dateInputLocales[I18n.locale]?.placeholders || {
      day: 'dd',
      month: 'mm',
      year: 'yyyy',
    }

    const dateParts = getDatePartsWithPlaceholders(I18n.locale, placeholders)

    const delimiter = dateParts
      .filter((part) => part.type === 'literal')
      .reduce((acc, curr) => [...acc, curr.value], [] as string[])

    const segmentParts = dateParts
      .filter((part) => ['month', 'day', 'year'].includes(part.type))
      .reduce((acc, curr) => [...acc, curr.type], [] as string[])

    const segments =
      (I18n.locale as string) === 'pseudo'
        ? psuedoSegmentOrder
        : [...(segmentParts as SegmentType[])]

    const dateTime = useDateTime()
    const dateInput = useDateInput({
      onChange,
      value,
      log: 'Display date',
    })

    const onClickClear = (e: React.MouseEvent<HTMLButtonElement>) => {
      setFocusTo(segment1Ref)
      dateInput.clear()
      onClear(e)
    }

    const getSegmentAriaLabel = (type: SegmentType, value: number) =>
      value && value !== -1
        ? I18n.t(type, {
            value,
            scope: 'core.dateInput.segment.ariaLabel.withValue',
          })
        : I18n.t(type, {
            scope: 'core.dateInput.segment.ariaLabel.withoutValue',
          })

    return (
      <StyledDateInput
        role="group"
        aria-label={
          value && isValidYearRange(dateInput.year)
            ? dateTime.format(value, 'weekday-date')
            : undefined
        }
        disabled={disabled || variant === 'disabled'}
        error={
          error ||
          variant === 'error' ||
          // -1 means placeholder yyyy is shown
          (!isValidYearRange(dateInput.year) && dateInput.year !== -1)
        }
        ref={dateInputRef}
        {...props}
        onClick={(e) => {
          focusTargetOrFirst(segment1Ref, segment2Ref, segment3Ref)(e)
          props.onClick?.(e)
        }}
      >
        <StyledDateSegmentsContainer>
          <DateSegment
            disabled={disabled}
            nextRef={segment2Ref}
            ref={segment1Ref}
            tabIndex={tabIndex}
            type={segments[0]}
            {...getSegmentProps(
              onChangeSegment,
              segments[0],
              placeholders[segments[0]],
              dateInput,
              getSegmentAriaLabel
            )}
          />
          {delimiter[0] && (
            <StyledDateInputDelimiter
              aria-hidden={true}
              visible={Boolean(value)}
            >
              {delimiter[0]}
            </StyledDateInputDelimiter>
          )}
          <DateSegment
            disabled={disabled}
            nextRef={segment3Ref}
            prevRef={segment1Ref}
            ref={segment2Ref}
            tabIndex={tabIndex}
            type={segments[1]}
            {...getSegmentProps(
              onChangeSegment,
              segments[1],
              placeholders[segments[1]],
              dateInput,
              getSegmentAriaLabel
            )}
          />
          {delimiter[1] && (
            <StyledDateInputDelimiter
              aria-hidden={true}
              visible={Boolean(value)}
            >
              {delimiter[1]}
            </StyledDateInputDelimiter>
          )}
          <DateSegment
            disabled={disabled}
            prevRef={segment2Ref}
            ref={segment3Ref}
            tabIndex={tabIndex}
            type={segments[2]}
            {...getSegmentProps(
              onChangeSegment,
              segments[2],
              placeholders[segments[2]],
              dateInput,
              getSegmentAriaLabel
            )}
          />
          {delimiter[2] && (
            <StyledDateInputDelimiter
              aria-hidden={true}
              visible={Boolean(value)}
            >
              {delimiter[2]}
            </StyledDateInputDelimiter>
          )}
        </StyledDateSegmentsContainer>
        <StyledDateInputIconContainer>
          {dateInput.hasValues ? (
            <Button
              aria-label={I18n.t('core.dateInput.clearButton.ariaLabel')}
              onClick={onClickClear}
              ref={clearRef}
              size="sm"
              variant="tertiary"
              icon={<Clear size="sm" />}
              tabIndex={-1} // TODO revisit this accessibility
            />
          ) : (
            <StyledCalendar />
          )}
        </StyledDateInputIconContainer>
      </StyledDateInput>
    )
  }
)

DateInput.displayName = 'DateInput'
