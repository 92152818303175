import React, { useState } from 'react'
import {
  StyledAnchor,
  StyledAnchorNavigation,
  StyledAnchorSection,
} from './AnchorNavigation.styles'

type AnchorNavigationProps<Section = SectionType> = {
  getId?: (section: Section) => number | string
  getLabel?: (section: Section) => string
  sections: Section[]
}

export type SectionType = any

type SectionProps = SectionType & {
  selected: boolean
  onClick: () => void
}

const Anchor = ({ label, selected, onClick }: SectionProps) => {
  return (
    <StyledAnchorSection $selected={selected} onClick={onClick}>
      <StyledAnchor aria-selected={selected} $selected={selected} tabIndex={0}>
        {label}
      </StyledAnchor>
    </StyledAnchorSection>
  )
}

export const AnchorNavigation = React.forwardRef<
  HTMLUListElement,
  AnchorNavigationProps
>(
  (
    {
      getId = (section) => section.id,
      getLabel = (section) => section.label,
      sections,
    },
    ref
  ) => {
    const [selected, setSelected] = useState<string | number | null>(null)

    return (
      <StyledAnchorNavigation ref={ref}>
        {sections?.map((section) => {
          const id = getId(section)
          const label = getLabel(section)

          return (
            <Anchor
              key={id}
              onClick={() => setSelected(id)}
              selected={selected === id}
              label={label}
              {...section}
            />
          )
        })}
      </StyledAnchorNavigation>
    )
  }
)
