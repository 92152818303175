import type { Locale } from './I18n'

// All and only locales that will receive smartling translations and can be directly fed into <I18nProvider>
const SUPPORTED_LOCALES: Locale[] = [
  'de-DE',
  'en-AU',
  'en-CA',
  'en-GB',
  'en',
  'es-ES',
  'es',
  'fr-CA',
  'fr-FR',
  'is-IS',
  'ja-JP',
  'pt-BR',
  'th-TH',
  'zh-SG',
]
/*
  all locales that should directly use a different locale. Any other unsupported locale will fallback to 'en'
  this should match https://github.com/procore/procore/blob/d0515461046b8d018c8fa8ede1ca8b7aa2fcacf8/config/application.rb#L234C1-L240C7
  for any locales that are not listed in SUPPORTED_LOCALES
*/
const OVERWRITE_LOCALE_MAP: Record<string, Locale> = {
  'en-AE': 'en-GB',
  'en-SG': 'en-GB',
  'es-419': 'es',
} as const

/**
 * Returns the locale that should be used for the I18nProvider based on the environment locale
 * @param envLocale - the locale from the environment
 * @returns the locale that should be used for the I18nProvider
 */
export function getI18nProviderLocale(envLocale: string): Locale {
  if (SUPPORTED_LOCALES.includes(envLocale as Locale)) {
    return envLocale as Locale
  }
  if (OVERWRITE_LOCALE_MAP[envLocale]) {
    return OVERWRITE_LOCALE_MAP[envLocale]
  }

  return 'en'
}
