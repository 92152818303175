import { Editor } from '@tinymce/tinymce-react'
import React from 'react'
import {
  generateExternalPlugins,
  tinyMCEConfig,
  tinyMCESource,
  tinyMCEVersion,
} from '../_utils/TinyMCE'
import type { DivAttributes } from '../_utils/types'
import { StyledEditor } from './TextEditorOutput.styles'
import type { TextEditorOutputProps } from './TextEditorOutput.types'

/**

 We use TextEditorOutput to render formatted text.

 @since 10.24.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-texteditoroutput--demo)

 */
export const TextEditorOutput = React.forwardRef<
  HTMLDivElement,
  TextEditorOutputProps & DivAttributes
>(function TextEditorOutput({ value, init: initOverrides, ...props }, ref) {
  return (
    <StyledEditor {...props} ref={ref}>
      <Editor
        value={value}
        init={{
          ...tinyMCEConfig,
          external_plugins: generateExternalPlugins(['autoresize']),
          toolbar: false,
          autoresize_overflow_padding: 0,
          min_height: 0,
          content_style: `body { margin: 0; padding: 0; } p { margin: 0; }`,
          ...initOverrides,
        }}
        tinymceScriptSrc={`${tinyMCESource}/${tinyMCEVersion}/tinymce.min.js`}
        disabled
      />
    </StyledEditor>
  )
})

TextEditorOutput.displayName = 'TextEditorOutput'
