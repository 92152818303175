export type Color =
  | 'blue10'
  | 'blue15'
  | 'blue20'
  | 'blue30'
  | 'blue40'
  | 'blue45'
  | 'blue50'
  | 'blue60'
  | 'blue70'
  | 'blue85'
  | 'blue90'
  | 'blue94'
  | 'blue96'
  | 'blue98'
  | 'cyan10'
  | 'cyan15'
  | 'cyan20'
  | 'cyan30'
  | 'cyan40'
  | 'cyan45'
  | 'cyan50'
  | 'cyan60'
  | 'cyan70'
  | 'cyan85'
  | 'cyan90'
  | 'cyan94'
  | 'cyan96'
  | 'cyan98'
  | 'gray10'
  | 'gray15'
  | 'gray20'
  | 'gray30'
  | 'gray40'
  | 'gray45'
  | 'gray50'
  | 'gray60'
  | 'gray70'
  | 'gray85'
  | 'gray90'
  | 'gray94'
  | 'gray96'
  | 'gray98'
  | 'green10'
  | 'green15'
  | 'green20'
  | 'green30'
  | 'green40'
  | 'green45'
  | 'green50'
  | 'green60'
  | 'green70'
  | 'green85'
  | 'green90'
  | 'green94'
  | 'green96'
  | 'green98'
  | 'magenta10'
  | 'magenta15'
  | 'magenta20'
  | 'magenta30'
  | 'magenta40'
  | 'magenta45'
  | 'magenta50'
  | 'magenta60'
  | 'magenta70'
  | 'magenta85'
  | 'magenta90'
  | 'magenta94'
  | 'magenta96'
  | 'magenta98'
  | 'orange10'
  | 'orange15'
  | 'orange20'
  | 'orange30'
  | 'orange40'
  | 'orange45'
  | 'orange50'
  | 'orange60'
  | 'orange70'
  | 'orange85'
  | 'orange90'
  | 'orange94'
  | 'orange96'
  | 'orange98'
  | 'purple10'
  | 'purple15'
  | 'purple20'
  | 'purple30'
  | 'purple40'
  | 'purple45'
  | 'purple50'
  | 'purple60'
  | 'purple70'
  | 'purple85'
  | 'purple90'
  | 'purple94'
  | 'purple96'
  | 'purple98'
  | 'red10'
  | 'red15'
  | 'red20'
  | 'red30'
  | 'red40'
  | 'red45'
  | 'red50'
  | 'red60'
  | 'red70'
  | 'red85'
  | 'red90'
  | 'red94'
  | 'red96'
  | 'red98'
  | 'yellow10'
  | 'yellow15'
  | 'yellow20'
  | 'yellow30'
  | 'yellow40'
  | 'yellow45'
  | 'yellow50'
  | 'yellow60'
  | 'yellow70'
  | 'yellow85'
  | 'yellow90'
  | 'yellow94'
  | 'yellow96'
  | 'yellow98'
  | 'black'
  | 'white'

const luminosities = [10, 15, 20, 30, 40, 45, 50, 60, 70, 85, 90, 94, 96, 98]

// Palette Generation

type ColorPaletteObject = { [key in Color]: string }

function colorGenerator(color: string, hue: number, saturation: number) {
  const colorSet = {} as ColorPaletteObject

  for (let index in luminosities) {
    const key = `${color}${luminosities[index]}` as unknown as Color

    colorSet[key] = `hsl(${hue}, ${saturation}%, ${luminosities[index]}%)`
  }

  return colorSet
}

function paletteGenerator(): ColorPaletteObject {
  return {
    ...colorGenerator('blue', 218, 75),
    ...colorGenerator('cyan', 170, 65),
    ...colorGenerator('gray', 200, 8),
    ...colorGenerator('green', 125, 50),
    ...colorGenerator('magenta', 310, 60),
    ...colorGenerator('orange', 19, 100),
    ...colorGenerator('purple', 288, 43),
    ...colorGenerator('red', 360, 70),
    ...colorGenerator('yellow', 45, 85),
    black: 'black',
    white: 'white',
  }
}

export const colors = paletteGenerator()
