import { Warning } from '@procore/core-icons'
import styled, { css } from 'styled-components'
import { Card } from '../Card/Card'
import { StyledSection, StyledSectionInner } from '../Section/Section.styles'
import { Typography } from '../Typography/Typography'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'
import type { ModalPlacement, ModalWidth } from './Modal.types'

export const fadeInClassName = 'fade-in'
export const fadeOutClassName = 'fade-out'
export const modalMaxWidth = '640px'
export const modalPixelGutter = spacing.xl
export const modalPixelWidths = {
  sm: 432,
  md: 688,
  lg: 1152,
  xl: 1920,
}

export const StyledModal = styled.div`
  display: flex;
  height: 100vh;
  position: absolute;
  opacity: 0;
  transition: 0.3s opacity ease;
  width: 100vw;
  z-index: 0;

  &.${fadeInClassName} {
    opacity: 1;
  }

  &.${fadeOutClassName} {
    opacity: 0;
  }
`

export const StyledModalButtons = styled.div`
  display: flex;
  flex: 0 0 auto;
  margin-left: ${spacing.lg}px;

  > * + * {
    margin-left: ${spacing.sm}px;
  }
`

export const StyledModalContainer = styled(Card)<{
  $placement: ModalPlacement
  $width?: ModalWidth
}>`
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 2;

  ${({ $placement }) => {
    if ($placement === 'top') {
      return css`
        top: 5vh;
      `
    }

    return css`
      top: 50vh;
      transform: translateY(-50%);
    `
  }}

  ${({ $width, $placement }) => {
    if ($width) {
      return css`
        left: ${modalPixelGutter}px;
        right: ${modalPixelGutter}px;
        margin-left: auto;
        margin-right: auto;
        max-width: ${modalPixelWidths[$width]}px;

        // TODO deprecated, moving width up
        ${StyledModalBody} {
          max-width: none;
        }
      `
    }
    // TODO deprecated, uses new centering, required width
    return css`
      left: 50vw;
      transform: translateX(-50%)
        ${$placement === 'center' && 'translateY(-50%)'};
    `
  }}
`

export const StyledModalFooter = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: ${spacing.xl}px ${spacing.xxl}px ${spacing.xxl}px;
`

export const StyledModalBody = styled.div<{
  isOverflowingY: boolean
  noSideSpacing: boolean
}>`
  ${getTypographyIntent('body')}

  box-sizing: border-box;
  color: ${colors.gray15};
  display: flex;
  flex-direction: column;
  // TODO deprecated, delete line in breaking change
  max-width: ${modalMaxWidth};
  overflow: auto;
  ${({ noSideSpacing }) => {
    return css`
      padding: ${spacing.xl}px ${noSideSpacing ? 0 : spacing.xxl}px;
    `
  }}

  > ${StyledSection} {
    padding-left: 0;
    padding-right: 0;

    &:first-child > ${StyledSectionInner} {
      padding-top: 0;
    }
  }

  ${({ isOverflowingY }) =>
    isOverflowingY &&
    css`
      & + ${StyledModalFooter} {
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: ${colors.gray85};
        ${StyledModalFooterSummary} {
          border-top: none;
        }
      }
    `}
`

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow: hidden;
  width: 100%;
`

export const StyledModalFooterNotation = styled(Typography)`
  flex-grow: 1;
`

export const StyledModalHeader = styled.div<{ $isBodyScrolled: boolean }>`
  align-items: flex-start;
  color: ${colors.gray10};
  display: flex;
  flex: 0 0 auto;
  padding: ${spacing.xl}px ${spacing.xxl}px;
  position: relative;
  ${({ $isBodyScrolled }) => css`
    border-bottom: 1px solid ${$isBodyScrolled ? colors.gray85 : 'transparent'};
  `}
`

export const StyledModalHeading = styled.div`
  ${getTypographyIntent('h2')}
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  min-height: 36px;
  padding-right: ${spacing.lg}px;
`

export const StyledModalCancel = styled.div`
  flex: 0 0 auto;
`

export const StyledModalScrim = styled.div`
  background: hsla(200, 8%, 10%, 0.6);
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 1;
`

export const StyledModalWarningIcon = styled(Warning)`
  color: ${colors.yellow40};
  margin-right: ${spacing.sm}px;
`
export const StyledModalFooterSummary = styled.div`
  ${getTypographyIntent('small')}
  border-top: 1px solid ${colors.gray85};
  border-bottom: 1px solid ${colors.gray85};
  padding: ${spacing.md}px ${spacing.xxl}px;
  color: ${colors.gray15};
  font-style: italic;
  background-color: ${colors.gray98};
  margin: -${spacing.xl}px -${spacing.xxl}px ${spacing.xl}px -${spacing.xxl}px;
  flex: 1 1 100%;
`
