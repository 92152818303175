import React from 'react'
import { Grid } from '../Grid/Grid'
import { Page } from '../PageLayout/PageLayout'
import type {
  PageComponentProps,
  PageFooterProps,
} from '../PageLayout/PageLayout.types'
import { Section, SectionProvider } from '../Section/Section'
import { H1 } from '../Semantic/Semantic'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type { Props } from '../_utils/types'
import { gridConfig } from './DetailPage.constants'
import {
  StyledDetailPageBody,
  StyledDetailPageCard,
  StyledDetailPageFooter,
  StyledDetailPageFooterActions,
  StyledDetailPageFooterContent,
  StyledDetailPageFooterNotation,
} from './DetailPage.styles'
import type { DetailPageProps, DetailPageWidth } from './DetailPage.types'

export const DetailPageViewContext = React.createContext<{
  width: DetailPageWidth
}>({
  width: 'md',
})

const DetailPage_ = React.forwardRef<HTMLDivElement, DetailPageProps>(
  function DetailPage_({ width = 'md', children, ...props }, ref) {
    return (
      <DetailPageViewContext.Provider value={{ width }}>
        <Page ref={ref} {...props}>
          {children}
        </Page>
      </DetailPageViewContext.Provider>
    )
  }
)

export const Heading = React.forwardRef<HTMLDivElement, Props>(function Heading(
  props,
  ref
) {
  return <H1 ref={ref} {...props} />
})

export const Body = React.forwardRef<HTMLDivElement, PageComponentProps>(
  function Body(props, ref) {
    const { width } = React.useContext(DetailPageViewContext)

    return (
      <Page.Body>
        <Grid.Row>
          <Grid.Col
            colWidth={gridConfig[width].width}
            colStart={gridConfig[width].offset}
          >
            <StyledDetailPageBody ref={ref} {...props} />
          </Grid.Col>
        </Grid.Row>
      </Page.Body>
    )
  }
)

export const Footer = React.forwardRef<HTMLDivElement, PageFooterProps>(
  function Footer({ children, ...props }, ref) {
    return (
      <StyledDetailPageFooter ref={ref} {...props}>
        <StyledDetailPageFooterContent>
          {children}
        </StyledDetailPageFooterContent>
      </StyledDetailPageFooter>
    )
  }
)

export const FooterNotation = React.forwardRef<HTMLDivElement, Props>(
  function FooterNotation(props, ref) {
    return <StyledDetailPageFooterNotation ref={ref} {...props} />
  }
)

export const FooterActions = React.forwardRef<HTMLDivElement, Props>(
  function Actions(props, ref) {
    return <StyledDetailPageFooterActions ref={ref} {...props} />
  }
)

export const Card = React.forwardRef<HTMLDivElement, Props>(function Card(
  props,
  ref
) {
  return (
    <SectionProvider>
      <StyledDetailPageCard ref={ref} {...props} />
    </SectionProvider>
  )
})

DetailPage_.displayName = 'DetailPage'
Heading.displayName = 'DetailPage.Heading'
Body.displayName = 'DetailPage.Body'
Footer.displayName = 'DetailPage.Footer'
Card.displayName = 'DetailPage.Card'

/**

 A detail page is used when an object is selected from a list page. The layout
 can adhere to varying predefined widths depending on use case.

  - DetailPage
    - DetailPage.Main
      - DetailPage.Header
        - DetailPage.Breadcrumbs
          - Breadcrumbs
        - DetailPage.Banner
          - Banner
        - DetailPage.Title
          - Title or H1
        - DetailPage.Tabs
          - Tabs
      - DetailPage.Body
        - DetailPage.Title
        - DetailPage.Banner
        - DetailPage.Card
          - DetailPage.Banner
          - DetailPage.Section
            - DetailPage.Section
          - DetailPage.Section
      - DetailPage.Footer
        - DetailPage.FooterNotation
        - DetailPage.FooterActions
          - Button
    - DetailPage.Aside
      - Panel

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-detailpage--edit)

 @see [Design Guidelines](https://design.procore.com/detail)

 */
export const DetailPage = addSubcomponents(
  {
    Main: Page.Main,
    Header: Page.Header,
    Breadcrumbs: Page.Breadcrumbs,
    Card,
    Banner: Page.Banner,
    Title: Page.Title,
    Heading,
    Tabs: Page.Tabs,
    Footer,
    FooterActions,
    FooterNotation,
    Section,
    Body,
    Aside: Page.Aside,
  },
  DetailPage_
)
