import {
  Calendar,
  DateSelect,
  dateTimeFormats,
  DateTimeProvider,
  Flex,
  H3,
  I18nProvider,
  Locale,
  UL,
  useDateTime,
} from '@procore/core-react'
import * as React from 'react'

const locales: Locale[] = [
  'en',
  'de-DE',
  'en-CA',
  'fr-CA',
  'fr-FR',
  'es',
  'es-ES',
  'en-AU',
  'en-AE',
  'en-GB',
  'en-SG',
  'ja-JP',
  'th-TH',
  'pt-BR',
  'is-IS',
  'zh-SG',
  'pseudo',
]

export function DateTimeLocaleFormats() {
  return (
    <UL>
      {Object.values(dateTimeFormats).map((format) => (
        <li>{format}</li>
      ))}
    </UL>
  )
}

export function DateTimeLocaleFormatsTable() {
  const date = new Date(2021, 0, 31, 14, 30, 42) // Sunday Jan 31 at 2:30 PM

  const Consumers = () => {
    const dateTime = useDateTime()

    return (
      <table style={{ tableLayout: 'fixed' }}>
        {Object.values(dateTimeFormats).map((format) => {
          return (
            <tr key={format}>
              <th style={{ textAlign: 'left', paddingRight: 8 }}>{format}:</th>
              <td>
                <time style={{ fontFamily: 'monospace' }}>
                  {dateTime.format(date, format)}
                </time>
              </td>
            </tr>
          )
        })}
      </table>
    )
  }

  return Object.values(locales).map((l) => (
    <React.Fragment key={l}>
      <H3>Locale: {l}</H3>
      <I18nProvider locale={l}>
        <DateTimeProvider>
          <Consumers />
        </DateTimeProvider>
      </I18nProvider>
      <br />
      <br />
    </React.Fragment>
  ))
}

export function DateTimeLocaleDateSelects() {
  return (
    <Flex wrap="wrap" style={{ gap: '16px 24px' }}>
      {locales.map((l) => (
        <div key={l}>
          <H3>Locale: {l}</H3>
          <I18nProvider locale={l}>
            <DateTimeProvider>
              <DateSelect />
            </DateTimeProvider>
          </I18nProvider>
        </div>
      ))}
    </Flex>
  )
}

export function DateTimeLocaleCalendars() {
  return (
    <Flex wrap="wrap" style={{ gap: '8px' }}>
      {locales.map((l) => (
        <div key={l}>
          <H3>Locale: {l}</H3>
          <I18nProvider locale={l}>
            <DateTimeProvider>
              <Calendar />
            </DateTimeProvider>
          </I18nProvider>
        </div>
      ))}
    </Flex>
  )
}
