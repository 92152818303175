import styled, { css } from 'styled-components'
import { Card } from '../Card/Card'
import { StyledItem } from '../MenuImperative/MenuImperative.styles'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

const hiddenTab = css`
  pointer-events: none;
  position: absolute;
  visibility: hidden;
`

const visible = css`
  pointer-events: all;
  position: relative;
  visibility: visible;
`

const borderRadius = css`
  border-radius: ${spacing.xs}px ${spacing.xs}px 0 0;
`

export const StyledLink = styled.span`
  align-items: center;
  display: flex;
  height: 23px; // TODO one-off
  ${borderRadius}
  box-sizing: border-box;
`

export const StyledTab = styled.div<{ $active?: boolean; $dark?: boolean }>`
  ${borderRadius}
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex: 0 0 auto;
  height: 26px;
  justify-content: center;
  position: relative;
  white-space: nowrap;

  a& {
    ${borderRadius}
    text-decoration: none;
  }

  button& {
    background-color: transparent;
    border: none;
    font-family: inherit;
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-block-start: 0;
    padding-block-end: 0;
  }

  &,
  a&,
  button& {
    ${({ $active }) => {
      if ($active) {
        return css`
          border-bottom: 3px solid ${colors.gray15};
        `
      }
    }}
  }

  &:focus,
  &:focus-within,
  &:hover {
    background: ${({ $dark }) => ($dark ? 'transparent' : colors.gray90)};
  }

  &:focus,
  &:focus-within {
    box-shadow: inset 0 0 0 2px ${colors.blue40};
    outline: none;

    a {
      outline: none;
    }
  }
`

export const StyledTabInner = styled.div<{
  $active?: boolean
  $dark?: boolean
}>`
  ${getTypographyIntent('body')}

  align-self: baseline;
  padding: 0 ${spacing.xs}px;
  box-sizing: border-box;
  font-weight: ${({ $active }) => ($active ? 600 : 400)};

  &,
  > a {
    ${borderRadius}
    color: ${colors.gray15};
    display: flex;
    height: 23px;
    text-decoration: none;
    width: 100%;
  }

  /* TODO: Dark mode was not considered for helix updates,
  *  matching previous UX patterns - April 2nd 2024
  */
  ${({ $active, $dark }) => {
    if ($dark) {
      return css`
        &,
        > a,
        button&,
        a& {
          color: ${$active ? colors.white : colors.gray70};

          &:hover {
            color: white;
          }
        }
      `
    }
  }}
`

// This is still needed so that it can be hidden via CSS down below
export const StyledDropdownTab = styled(StyledTab)`
  ${hiddenTab}
`

export const StyledDropdownTabInner = styled(StyledTabInner)`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
`

export const StyledOverlay = styled(Card)`
  max-height: 40vh;

  ${StyledItem} {
    padding: 0;
    display: flex;
    justify-content: center;

    a {
      margin: unset;
      padding: unset;
    }
  }

  ${StyledLink} {
    padding: 0;
    display: flex;
  }

  ${StyledTabInner} {
    padding: 2px 16px 4px 16px;
  }

  ${StyledLink}, ${StyledTab} {
    color: ${colors.gray15};
    border-radius: 0;
    display: flex;
    width: 100%;
  }

  [data-selected='true'] {
    ${StyledLink}, ${StyledTab} {
      border-bottom-color: transparent;
      color: ${colors.blue50};
    }
  }
`

export const StyledTabList = styled.div`
  display: flex;
  width: 100%;
`

export const StyledTabs = styled.nav<{ dark?: boolean; index?: number }>`
  background-color: ${({ dark }) => dark && colors.gray15};
  display: flex;
  overflow: hidden;
  width: 100%;

  ${StyledTab} + ${StyledTab} {
    margin-left: ${spacing.xl}px;
  }

  ${StyledTab} {
    // we want to hide all tabs after overflow index
    &:nth-child(1n + ${({ index }) => index}) {
      // but not the "More" dropdown tab
      &:not(${StyledDropdownTab}) {
        ${hiddenTab}
      }
    }
  }

  ${({ index = -1 }) =>
    index >= 0 &&
    css`
      ${StyledDropdownTab} {
        ${visible}
      }
    `}
`
