import { Clear } from '@procore/core-icons/dist'
import classNames from 'classnames'
import { isNil } from 'ramda'
import React from 'react'
import { Box } from '../Box/Box'
import {
  MenuImperative,
  useMenuImperativeControlNavigation,
} from '../MenuImperative/MenuImperative'
import type { MenuRef, Selection } from '../MenuImperative/MenuImperative.types'
import { Notation } from '../Notation/Notation'
import {
  OverlayTrigger,
  useOverlayTriggerContext,
} from '../OverlayTrigger/OverlayTrigger'
import { Spinner } from '../Spinner/Spinner'
import { Tooltip } from '../Tooltip/Tooltip'
import { useI18nContext } from '../_hooks/I18n'
import { OverflowObserver } from '../_hooks/OverflowObserver'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type { Props } from '../_utils/types'
import {
  StyledSelectArrow,
  StyledSelectArrowContainer,
  StyledSelectButton,
  StyledSelectButtonLabel,
  StyledSelectClearIcon,
  StyledSelectMenu,
  StyledSelectSpinner,
} from './Select.styles'
import type {
  SelectButtonProps,
  SelectMenuProps,
  SelectOptionProps,
  SelectProps,
} from './Select.types'

function noop() {}
function isFunction(obj: any) {
  return typeof obj === 'function'
}
function isOpen({ open = false }) {
  return open
}

export const OptGroup = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => (
    <MenuImperative.Group {...props} clickable={false} ref={ref} />
  )
)

export const Option = React.forwardRef<HTMLDivElement, SelectOptionProps>(
  ({ value, ...props }, ref) => (
    <MenuImperative.Item ref={ref} {...props} item={value} />
  )
)

export const Button = React.forwardRef<HTMLDivElement, SelectButtonProps>(
  function Button(
    {
      block = false,
      children,
      className,
      clearRef,
      disabled = false,
      error = false,
      focus = false,
      label,
      loading = false,
      onClear,
      open = false,
      placeholder,
      qa,
      tabIndex = 0,
      isMenuOpened = false,
      ...props
    },
    ref
  ) {
    const i18n = useI18nContext()
    const hasClearIcon = !disabled && !loading && Boolean(label)

    const content = children || label || placeholder

    return (
      <StyledSelectButton
        className={classNames(className, {
          focus: isFunction(focus) ? (focus as Function)({ open }) : focus,
        })}
        ref={ref}
        tabIndex={disabled ? -1 : tabIndex}
        role="button"
        $block={block}
        $error={error}
        $disabled={disabled}
        $hasClearIcon={hasClearIcon}
        $loading={loading}
        $open={open}
        $placeholder={!label}
        {...props}
      >
        <OverflowObserver>
          {({ isOverflowingX, ref: overflowRef }) => {
            const showTooltip = !disabled && isOverflowingX && !isMenuOpened
            const trigger = (
              <StyledSelectButtonLabel
                data-qa={qa?.label}
                $hoverable={showTooltip}
                ref={overflowRef}
              >
                {content}
              </StyledSelectButtonLabel>
            )

            return showTooltip ? (
              <Tooltip trigger="hover" overlay={content}>
                {trigger}
              </Tooltip>
            ) : (
              trigger
            )
          }}
        </OverflowObserver>
        {onClear && (
          <StyledSelectClearIcon
            aria-hidden={true} // prevents reading "delete field" button when select in focus
            aria-label={i18n.t('core.select.clear')}
            ref={clearRef}
            data-qa={qa?.clear || 'core-select-clear'}
            size="sm"
            variant="tertiary"
            icon={<Clear />}
            onClick={onClear}
            onMouseDown={(e) => e.preventDefault()} // prevents an element from getting the focus
            tabIndex={-1} // TODO revisit this accessibility
          />
        )}
        {loading ? (
          <StyledSelectSpinner>
            <Spinner color="blue50" size="xs" />
          </StyledSelectSpinner>
        ) : (
          <StyledSelectArrowContainer>
            <StyledSelectArrow />
          </StyledSelectArrowContainer>
        )}
      </StyledSelectButton>
    )
  }
)

const SelectMenu = React.forwardRef<HTMLDivElement, SelectMenuProps>(
  function SelectMenu(
    {
      onSearch: onSearch_,
      footer,
      emptyMessage,
      optionsRef,
      children,
      i18nScope,
      onSelect: onSelect_ = noop,
      menuRef,
      ...props
    },
    ref
  ) {
    const ctx = useOverlayTriggerContext()

    const [searchValue, setSearchValue] = React.useState('')
    const { menuProps, menuNavigationTriggerProps } =
      useMenuImperativeControlNavigation(menuRef, Boolean(onSearch_))

    React.useEffect(() => {
      menuRef.current?.highlightFirst()
    }, [menuRef, searchValue])

    React.useEffect(() => {
      menuRef.current?.highlightSuggested()
      menuRef.current?.highlightSelected()
    }, [menuRef])

    function onKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
      props.onKeyDown?.(e)
      // This is disconnected from the OverlayTrigger closing on Escape.
      // Closing a dropdown with Escape will not close a Modal, because stopP.
      // It is called only once because the menu component is unmounted.
      // The next Escape will propagate.
      if (e.key === 'Escape' || e.key === 'Esc') {
        e.stopPropagation()
      }
    }

    function onSelect(selection: Selection) {
      onSelect_(selection)

      ctx.hide(selection.event)
    }

    function onSearch(event: React.ChangeEvent<HTMLInputElement>) {
      setSearchValue(event.target.value)

      onSearch_?.(event)
    }

    return (
      <StyledSelectMenu ref={ref} shadowStrength={2}>
        <MenuImperative
          {...props}
          {...menuProps}
          ref={menuRef}
          role="listbox"
          onKeyDown={onKeyDown}
          onSelect={onSelect}
          circular
        >
          {onSearch_ && (
            <MenuImperative.Search
              autoComplete="false"
              i18nScope={i18nScope}
              onChange={onSearch}
              {...menuNavigationTriggerProps}
            />
          )}
          {React.Children.count(children) ? (
            <MenuImperative.Options ref={optionsRef}>
              {children}
            </MenuImperative.Options>
          ) : (
            <Box padding="md lg">
              <Notation variant="pagination">{emptyMessage}</Notation>
            </Box>
          )}
          {footer && <MenuImperative.Footer>{footer}</MenuImperative.Footer>}
        </MenuImperative>
      </StyledSelectMenu>
    )
  }
)

const Select_ = React.forwardRef<HTMLDivElement, SelectProps>(function Select(
  {
    afterHide: afterHide_ = noop,
    afterShow: afterShow_ = noop,
    beforeHide = () => true,
    beforeShow = () => true,
    block = false,
    children,
    className,
    container,
    disabled = false,
    emptyMessage = 'No results',
    error = false,
    footer,
    hideDelay = 100,
    i18nScope = 'core.select',
    label = '',
    loading = false,
    onClear: onClear_,
    onKeyDown = noop,
    onScrollBottom,
    onSearch,
    onSelect = noop,
    optionsRef,
    placeholder,
    placement = 'bottom-left',
    showDelay = 0,
    tabIndex = 0,
    qa,
    ...props
  },
  forwardRef
) {
  const menuRef = React.useRef<MenuRef>(null)
  const overlayTriggerRef = React.useRef<HTMLDivElement>(null)
  const ref =
    (forwardRef as React.RefObject<HTMLDivElement>) || overlayTriggerRef
  const [isMenuOpened, setIsMenuOpened] = React.useState(false)

  const clearRef = React.useRef<HTMLButtonElement>(null)

  function afterHide() {
    afterHide_()
    setIsMenuOpened(false)
    onSearch?.({
      target: { value: '' },
    } as React.ChangeEvent<HTMLInputElement>)
  }

  const onClear = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onClear_?.(e)
      // IE 11 fix
      // Return focus back to trigger instead of the clear button
      overlayTriggerRef?.current?.focus()
    },
    [onClear_]
  )

  function afterShow() {
    afterShow_()
    setIsMenuOpened(true)
  }

  return (
    <OverlayTrigger
      autoFocus
      afterHide={afterHide}
      afterShow={afterShow}
      beforeHide={(e: Event) => {
        if (e.target === clearRef.current) {
          return false
        }

        return beforeHide(e)
      }}
      beforeShow={beforeShow}
      canFlip={true}
      container={container}
      hideDelay={hideDelay}
      overlay={
        <SelectMenu
          onSearch={onSearch}
          footer={footer}
          emptyMessage={emptyMessage}
          optionsRef={optionsRef}
          i18nScope={i18nScope}
          onSelect={onSelect}
          onScrollBottom={onScrollBottom}
          menuRef={menuRef}
        >
          {children}
        </SelectMenu>
      }
      placement={placement}
      ref={ref}
      showDelay={showDelay}
      trigger={disabled ? 'none' : 'click'}
    >
      <Button
        {...props}
        block={block}
        className={className}
        clearRef={clearRef}
        disabled={disabled}
        error={error}
        focus={onSearch ? false : isOpen}
        label={label}
        loading={loading}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onClear={isNil(onClear_) ? onClear_ : onClear}
        isMenuOpened={isMenuOpened}
        tabIndex={tabIndex}
        qa={qa}
      />
    </OverlayTrigger>
  )
})

Select_.displayName = 'Select'

Button.displayName = 'Select.Button'

Option.displayName = 'Select.Option'

OptGroup.displayName = 'Select.OptGroup'

/**

 We use single selects to allow our users to choose a single option from a list,
 presented in a dropdown. We typically see these selects on forms.

 If you want users to select multiple options, use a multi select, group select,
 and tiered select if you want users to select from a tiered set of options,
 use a tiered select.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-select--demo)

 @see [Design Guidelines](https://design.procore.com/select)

 */
export const Select = addSubcomponents(
  {
    Button,
    Option,
    OptGroup,
  },
  Select_
)
