import styled, { css } from 'styled-components'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { getEllipsis } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

export const StyledAnchorNavigation = styled.ul`
  max-width: 200px;
  margin: 0;
  padding-inline-start: 0;
`

export const StyledAnchorSection = styled.li<{ $selected: boolean }>`
  display: flex;
  cursor: pointer;
  border-left: ${spacing.xs}px solid
    ${({ $selected }) => ($selected ? colors.gray15 : colors.gray90)};
  background-color: ${({ $selected }) =>
    $selected ? colors.gray90 : 'transparent'};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  :hover {
    border-left: ${spacing.xs}px solid
      ${({ $selected }) => ($selected ? colors.gray15 : colors.gray60)};
    background-color: ${colors.gray90};
  }
`
export const StyledAnchor = styled.a<{ $selected: boolean }>`
  width: 100%;
  padding: 6px ${spacing.sm}px;
  ${getTypographyIntent('body')}

  ${({ $selected }) =>
    $selected
      ? css`
          font-weight: 600;
        `
      : ''};

  ${getEllipsis()}

  :focus-visible {
    box-shadow: inset 0 0 0 2px ${colors.blue50};
    outline: none;
  }
`
