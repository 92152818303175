import type { I18njs, I18njsConfig } from '@procore/core-i18n-js'
import { createI18njs } from '@procore/core-i18n-js'
import * as R from 'ramda'
import React from 'react'
import deDE from '../_locales/de-DE.json'
import enAU from '../_locales/en-AU.json'
import enCA from '../_locales/en-CA.json'
import enGB from '../_locales/en-GB.json'
import en from '../_locales/en.json'
import esES from '../_locales/es-ES.json'
import es from '../_locales/es.json'
import frCA from '../_locales/fr-CA.json'
import frFR from '../_locales/fr-FR.json'
import isIS from '../_locales/is-IS.json'
import jaJP from '../_locales/ja-JP.json'
import ptBR from '../_locales/pt-BR.json'
import thTH from '../_locales/th-TH.json'
import zhSG from '../_locales/zh-SG.json'

type I18nConfig = Omit<I18njsConfig, 'I18n'>

export type Locale =
  | 'de-DE'
  | 'en-AE'
  | 'en-AU'
  | 'en-CA'
  | 'en-GB'
  | 'en-SG'
  | 'en'
  | 'es-ES'
  | 'es'
  | 'fr-CA'
  | 'fr-FR'
  | 'is-IS'
  | 'ja-JP'
  | 'pt-BR'
  | 'th-TH'
  | 'zh-SG'
  | 'pseudo'

/* Set up i18n-js */
export const coreTranslations = {
  'de-DE': deDE,
  en,
  'en-AU': enAU,
  'en-CA': enCA,
  'en-GB': enGB,
  es,
  'es-ES': esES,
  'fr-CA': frCA,
  'fr-FR': frFR,
  'is-IS': isIS,
  'ja-JP': jaJP,
  'pt-BR': ptBR,
  'th-TH': thTH,
  'zh-SG': zhSG,
} as const

function getValidLocale({ locale, translations }: I18njsConfig) {
  return locale
}

export function useI18n({
  locale: _locale,
  translations: _translations = {},
}: I18njsConfig): I18njs {
  const translations = R.mergeDeepLeft(_translations, coreTranslations)
  const locale = getValidLocale({ locale: _locale, translations })
  return createI18njs({
    locale,
    translations,
  })
}

export const I18nContext: React.Context<I18njs> = React.createContext(
  createI18njs({
    locale: 'en',
    translations: coreTranslations, // inject our translations
  })
)

export function I18nProvider({
  children,
  ...props
}: I18nConfig & { children: React.ReactNode }) {
  return (
    <I18nContext.Provider value={useI18n(props)}>
      {children}
    </I18nContext.Provider>
  )
}

export function useI18nContext() {
  const ctx = React.useContext(I18nContext)

  if (!ctx) {
    // TODO investigate a way to do warnings that won't annoy people
    // we probably need to introduce a build system to have nice dev warnings
    // console.warn()
  }

  return ctx
}
