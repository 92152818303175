import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "change-log",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#change-log",
        "aria-label": "change log permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Change Log`}</h1>
    <h2 {...{
      "id": "1210",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1210",
        "aria-label": "1210 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.1.0`}</h2>
    <h3 {...{
      "id": "minor-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes",
        "aria-label": "minor changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`d159f925da: Add missed exports of Dropzone for file-select library internal development`}</li>
    </ul>
    <h2 {...{
      "id": "1200-2024-05-28",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1200-2024-05-28",
        "aria-label": "1200 2024 05 28 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.0.0 (2024-05-28)`}</h2>
    <h3 {...{
      "id": "major-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#major-changes",
        "aria-label": "major changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Major Changes`}</h3>
    <p>{`See `}<a parentName="p" {...{
        "href": "https://core.procore.com/latest/web/releases/migration-guides/core-react/v11-to-v12/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`v12 migration guide`}</a>{` for the full list of changes and migration guides.`}</p>
    <p>{`General changes:`}</p>
    <ul>
      <li parentName="ul">{`Imports from `}<inlineCode parentName="li">{`dist`}</inlineCode>{` folder do not work anymore.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`@procore/globalization-toolkit`}</inlineCode>{` is moved from dependencies to required peerDependencies.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`react-beautiful-dnd`}</inlineCode>{` is moved from dependencies to required peerDependencies.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`formik`}</inlineCode>{` peerDependency is now optional. Necessary when using `}<inlineCode parentName="li">{`Form`}</inlineCode>{` components and hooks.`}</li>
      <li parentName="ul">{`resize observer polyfill remove`}</li>
    </ul>
    <p>{`Other changes:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`NumberInput`}</inlineCode>{` no longer uses the `}<inlineCode parentName="li">{`locale`}</inlineCode>{` as prop from the component.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Page`}</inlineCode>{` and `}<inlineCode parentName="li">{`DetailPage`}</inlineCode>{` subcomponent padding refactor.`}</li>
      <li parentName="ul">{`Removed deprecated components, hooks, and contexts:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`SuccessBanner`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`Sidebar`}</inlineCode>{`, `}<inlineCode parentName="li">{`Sidebar.*`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`Analytics`}</inlineCode>{`, `}<inlineCode parentName="li">{`AnalyticsContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`AnalyticsProvider`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`AnchorContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`AnchorProvider`}</inlineCode>{`, `}<inlineCode parentName="li">{`useAnchorContext`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Autofocus`}</inlineCode>{`, `}<inlineCode parentName="li">{`useAutofocus`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`BoundingRectContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`useBoundingRectContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`useBoundingRect`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`BufferContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`useBufferContext`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Event`}</inlineCode>{`, `}<inlineCode parentName="li">{`EventProvider`}</inlineCode>{`, `}<inlineCode parentName="li">{`useEvent`}</inlineCode>{`, `}<inlineCode parentName="li">{`useEventContext`}</inlineCode>{` etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`InjectedScriptContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`InjectedScriptProvider`}</inlineCode>{`, `}<inlineCode parentName="li">{`useInjectedScriptContext`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`ListNavigationContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`ListNavigationProvider`}</inlineCode>{`, `}<inlineCode parentName="li">{`useListNavigationContext`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`ResizeObserver`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`TextEntryContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`TextEntryProvider`}</inlineCode>{`, `}<inlineCode parentName="li">{`useTextEntryContext`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`TimerContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`TimerProvider`}</inlineCode>{`, `}<inlineCode parentName="li">{`useTimerContext`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`TriggerContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`TriggerProvider`}</inlineCode>{`, `}<inlineCode parentName="li">{`useTriggerContext`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`VisibilityContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`VisibilityProvider`}</inlineCode>{`, `}<inlineCode parentName="li">{`useVisibilityContext`}</inlineCode>{`, etc`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      